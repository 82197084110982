import * as React from "react";
import Layout from "../_components/Layout/Layout";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import Banner from "../_components/Banner/Banner";
import RewardsCarousel from "../_components/RewardsCarousel/RewardsCarousel";
import ClaimRewardBtn from "../_components/ClaimRewardBtn/ClaimRewardBtn";
import ShareActionBlock from "../_components/ShareActionBlock/ShareActionBlock";
import {useEffect, useState} from "react";

const EventPromotions = () => {
    const [isIOS, setIsIOS] = useState(true);

    function iOS() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            || ((navigator.userAgent.includes("Mac") || (navigator.userAgent.includes("iPhone"))) && "ontouchend" in document);
    }

    useEffect(() => {
        setIsIOS(iOS());
    }, []);
    return (
        <Layout title="WELCOME TO GLIDE @ BATTERSEA POWER STATION">
            <div className="video-wrapper">
                {isIOS ?
                    (<div className="main-image-cover-background">
                        <img src="/images/glide/main-image-bg.jpg" alt="ios main bg"/>
                    </div>) :
                    (<video className="video-background" autoPlay="autoplay" playsInline muted loop>
                        <source src="/video.mp4" type="video/mp4"/>
                    </video>)}

                <div className="title-wrapper">
                    <MainTitle>
                        SOME OF THE GIFTS WAITING FOR YOU
                    </MainTitle>

                    <PageSummary>Scan a QR code to claim yours now! Everyone wins!</PageSummary>
                </div>

                <div class="video-overlay"></div>
            </div>

            {/* <PageSummary>
                Scan a QR code to claim yours now! Everyone wins
            </PageSummary> */}

            <ClaimRewardBtn/>

            <Banner
                style="style_2"
                image="/images/glide/Jo-Malone-Landing-page.jpg"
                text="GIFTS FROM JO MALONE"
            />

            <Banner
                style="style_2"
                image="https://assets.xela.co/glb/51c70cde-85c7-4008-ab0d-81bca580c8fe.jpg"
                text="TICKETS TO TASTE OF LONDON"
            />

            <Banner
                style="style_2"
                image="/images/glide/Tickets-to-glide.jpg"
                text="£250 Voucher from No 29 Power Station West"
            />

            <RewardsCarousel></RewardsCarousel>

            <ClaimRewardBtn/>
        </Layout>
    );
};

export default EventPromotions;
